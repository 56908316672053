<template>
  <div class="file-status">
    <div class="text-center">
      <v-chip
        v-if="account.accountType !== 'lawyer'"
        :color="caseStatus.color"
        :text-color="caseStatus.textColor"
      >
        {{ caseStatus.text }}
      </v-chip>
      <v-menu
        v-else
        bottom
        origin="center top"
        transition="scale-transition"
        offset-y
        :disabled="disabled"
      >
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-chip
                :color="caseStatus.color"
                :text-color="caseStatus.textColor"
                v-on="{ ...tooltip, ...menu }"
              >
                {{ caseStatus.text }}
                <v-icon right>
                  mdi-chevron-down
                </v-icon>
              </v-chip>
            </template>
            <span>Click to change Status</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="status in caseStatuses"
            :key="status.value"
            @click="changeStatus(status)"
          >
            <v-list-item-title>{{ status.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { caseStatuses, parseCaseStatus } from '@/utils/index'

export default {
  props: {
    caseFile: {
      type: Object,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      caseStatuses
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    caseStatus () {
      return parseCaseStatus(this.caseFile.status)
    }
  },
  methods: {
    changeStatus (status) {
      this.$emit('changeStatus', status)
    }
  }
}
</script>
